<template>
  <el-button
    :type="type"
    class="pz-large-margin"
    :icon="icon"
    @click="handleClick"
    @keydown="handleEnter"
  >
    {{ label }}
  </el-button>
</template>


<script>

export default {
    name: 'PzForwardButton',
    props: {
        buttonName: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'primary'
        },
        forward: {
            type: Function,
            required: true,
        },
        icon: {
            type: String,
            default: 'el-icon-inv'
        },
        keyPress: {
            type: [Number],
            default: 13,
        },
    },
    data() {
        return {
            label: (this.buttonName ? this.buttonName : this.$t('continue'))
        }
    },
    created() {
        window.addEventListener('keydown', this.handleEnter,true)
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleEnter,true);
    },
    methods: {
        handleEnter(event) {
            if (this.keyPress && event.keyCode === this.keyPress) {
                this.handleClick();
            }
        },
        handleClick() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            return new Promise((resolve) => {
                let forw = this.forward();
                resolve(forw);
            }).finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name})
            });
        }
    }
}


</script>
