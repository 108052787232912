<template>
  <el-button
    :type="type"
    class="pz-large-margin"
    :icon="icon"
    @click="handleClick"
    @keydown="handleBackSpace"
  >
    {{ label }}
  </el-button>
</template>


<script>

export default {
    name: 'PzBackButton',
    props: {
        buttonName: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'default'
        },
        back: {
            type: Function,
            default: null
        },
        icon: {
            type: String,
            default: 'el-icon-arrow-left'
        },
        keyPress: {
            type: [Number],
            default: null,
        },

    },
    componentName: 'PzBackButton',
    data() {
        return {
            label: (this.buttonName ? this.buttonName : this.$t('go_back'))
        }
    },
    created() {
        window.addEventListener('keydown', this.handleBackSpace, true)
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleBackSpace, true);
    },
  
    methods: {
        handleClick() {
            if (this.back)
                return this.back();
            return this.$router.go(-1);
        },
        handleBackSpace(event) {
            if (this.keyPress && event.keyCode === this.keyPress) {
                this.handleClick();
            }
        },
    }
}


</script>
