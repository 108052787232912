<template>
  <el-button
    :type="type"
    class="pz-large-margin"
    :icon="icon"
    @click="handleClick"
    @keydown="handleEnter"
  >
    {{ label }}
    <vue-programmatic-invisible-google-recaptcha
      ref="invisibleRecaptcha"
      style="display: none"
      :sitekey="capchakey"
      :element-id="'invisibleRecaptcha'"
      :badge-position="'left'"
      :show-badge-mobile="false"
      :show-badge-desktop="false"
      @recaptcha-callback="recaptchaCallback"
    />
  </el-button>
</template>


<script>

export default {
    name: 'PzCaptchaButton',
    props: {
        buttonName: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'primary'
        },
        forward: {
            type: Function,
            required: true,
        },
        keyPress: {
            type: [Number],
            default: 13,
        },
        icon: {
            type: String,
            default: 'el-icon-inv'
        }
    },
    componentName: 'PzForwardButton',
    data() {
        return {
            capchakey: process.env.VUE_APP_CAPCHA_KEY,
            label: (this.buttonName ? this.buttonName : this.$t('continue'))
        }
    },
    created() {
        window.addEventListener('keydown', this.handleEnter,true)
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleEnter,true);
    },
    methods: {
        handleEnter(event) {
            if (this.keyPress && event.keyCode === this.keyPress) {
                this.handleClick();
            }
        },
        handleClick() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            this.$refs.invisibleRecaptcha.execute();
        },
        recaptchaCallback(recaptchaToken) {
            this.$emit('input', recaptchaToken);
            return new Promise((resolve) => {
                let forw = this.forward();
                resolve(forw)
            }).finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name})
            });
        },
    }
}


</script>
