<template>
  <el-button
    :type="type"
    class="pz-large-margin"
    :icon="icon"
    @click="handleClick"
    @keydown="handleKeyDown"
  >
    {{ label }}
  </el-button>
</template>


<script>

export default {
    name: 'PzButton',
    props: {
        buttonName: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'success'
        },
        icon: {
            type: String,
            default: 'el-icon-inv'
        },
        keyPress: {
            type: [Number],
            default: null,
        },

    },
    componentName: 'PzButton',
    data() {
        return {
            label: this.buttonName
        }
    },
    created() {
        window.addEventListener('keydown', this.handleKeyDown, true)
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown, true);
    },
    methods: {
        handleClick(evt) {
            this.$emit('click', evt);
        },
        handleKeyDown(event) {
            if (this.keyPress && event.keyCode === this.keyPress) {
                this.handleClick();
            }
        },
    }
}


</script>
