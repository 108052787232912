import util from './util/index'
import * as stringFilters from './string/index'
import * as arrayFilters from './array/index'
import * as otherFilters from './other/index'
import settings from "@/settings/settings";


var Vue2Filters = {
    install: function(Vue) {
        let options=settings.filters;
        util.each(stringFilters, function(value, key) {
            Vue.filter(key, value.bind(options))
        })

        util.each(otherFilters, function(value, key) {
            Vue.filter(key, value.bind(options))
        })
    },
    mixin: {
        methods: {
            limitBy: arrayFilters.limitBy,
            filterBy: arrayFilters.filterBy,
            orderBy: arrayFilters.orderBy,
            find: arrayFilters.find
        }
    }
}

export default Vue2Filters;



